import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../components/Layout';
import MarkdownPageTemplate from '../components/shared/MarkdownPage';
import { withHelmet } from '../helpers/metadataHelpers';

const JobsPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <MarkdownPageTemplate
        title={post.frontmatter.title}
        content={post.frontmatter.content}
      />
    </Layout>
  )
}

JobsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withHelmet(JobsPage)

export const aboutPageQuery = graphql`
  query JobsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        content {
          copy
          textalign
          media {
            embed
            caption
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 88) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
